<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Expertise médicale" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Modifier une expertise médicale</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="name"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- La profession médicale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">profession médicale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="professions"
                    v-model="selectedProfession"
                    item-text="label"
                    item-value="codeMos"
                    no-data-text="aucune profession"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>   
              <v-divider></v-divider>           
            </v-card-text>

            <!-- La spécialité médicale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">spécialité médicale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="availableSpecialities"
                    v-model="selectedSpeciality"
                    item-text="digitalName"
                    item-value="digitalName"
                    no-data-text="aucune spécialité"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>              
            </v-card-text>
            
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { MedicalExpertiseService } from "@/service/practitioners/medical_expertise_service.js"
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { MedicalProfessionService } from "@/service/practitioners/medical_profession_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";
import * as Logger from "@/tools/logger.js"


export default {
  name: "EditMedicalExpertise",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de la vue */
      service: null,
      serviceMedicalSpeciality: null,
      serviceMedicalProfession: null,

      /** l'identifiant à éditer */
      entityId: null,

      /** le nom */
      name: null,

      /** la liste des profession médicale */
      professions: [],
      /** la liste des spécialité médicales */
      specialities: [],

      /** La profession sélectionnée */
      selectedProfession: null,
      /** La spécialité sélectionnée */
      selectedSpeciality: null,

      /** la source */
      source: null,

      // Affiche la fenêtre d'info
      showStandardDialog: false,

    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération du tarif
        let entity = await this.service.getById(this.entityId);
        // Récupère toutes les profession médicales
        this.professions = await this.serviceMedicalProfession.getAll();
        // Récupère toutes les spécialité médicales
        this.specialities = await this.serviceMedicalSpeciality.getAll();

        if (entity.medicalProfessionCodeMos) {
          this.selectedProfession = this.professions.find((p) => p.codeMos == entity.medicalProfessionCodeMos);
        }
        if (entity.medicalSpecialtyId) {
          this.selectedSpeciality = this.specialities.find((s) => s.id == entity.medicalSpecialtyId);
        }

        this.source = JSON.parse(JSON.stringify(entity));

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    init() {
      this.name = this.source.name;
    },

    async save() {
      try {
        let entity = JSON.parse(JSON.stringify(this.source));
        entity.name = this.name;
        entity.medicalProfessionCodeMos = this.selectedProfession.codeMos;

        entity.medicalSpecialtyId = null;
        if (this.selectedSpeciality) {
          entity.medicalSpecialtyId = this.selectedSpeciality.id
        }

        Logger.debug("entity to save", entity);
        await this.service.update(entity);

        this.source = JSON.parse(JSON.stringify(entity));

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },
  },
  computed: {
    completed() {
      if (!this.name) return false;
      if (!this.selectedProfession) return false;

      // Condition : si la profession sélectionnée est associée à des spécialité médicales, 
      // le choix de la spécialité médicale devient obligatoire
      if (this.availableSpecialities.length > 0 && !this.selectedSpeciality) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (!areTheSame(this.source.name, this.name)) {
        changed = true;
      }
      if (this.source.medicalProfessionCodeMos != this.selectedProfession.codeMos) {
        changed = true;
      }
      if (this.selectedSpeciality) {

        if (this.source.medicalSpecialtyId != this.selectedSpeciality.id) {
          changed = true;
        }
      }

      return changed;
    },

    // Retourne les spécialités disponibles en fonction de la profession sélectionnée
    availableSpecialities() {
      let available = [];

      if (this.selectedProfession) {
        available = this.specialities.filter((s) => s.medicalProfessionId == this.selectedProfession.codeMos);
      }      

      return available;
    },
  },
  mounted() {

    // Instanciation des services
    this.service = new MedicalExpertiseService(this.$api.getMedicalExpertisesApi());
    this.serviceMedicalSpeciality = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi());
    this.serviceMedicalProfession = new MedicalProfessionService(this.$api.getMedicalProfessionApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>